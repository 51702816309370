(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name app.component:tags
     * @description
     * Componente para incluir un componente de etiquetas. Similar al componente tesauro pero en un mismo input.
     *
     * __Nota:__ `restricted` debería ser siempre true, ya que se desaconseja crear elementos de manera dinámica en base de datos.
     *
     * Referencia: {@link http://mbenford.github.io/ngTagsInput/}
     *
     * @param {Object} ngModel Objeto en el que se almacenará el resultado
     * @param {String} id ID del input
     * @param {String} label Etiqueta
     * @param {String} placeholder Placeholder del input
     * @param {String} tooltip Etiqueta del tooltip
     * @param {String} [key='id'] Clave única para el track by
     * @param {String} [display='nombre'] Propiedad que se va a mostrar
     * @param {Object} resource Servicio de tipo $resource sobre el que se ejecutará la operación de búsqueda
     * @param {String} [method='query'] Método del servicio (resource) que se utilizará en las búsquedas
     * @param {String} [queryParam='query'] Atributo en el que va a ir el texto de búsqueda al realizar la petición
     * @param {Object} parameters Parámetros que se enviarán en la petición. Útil para paginar resultados o obtenerlos ordenados
     * @param {Boolean} [required=false] Indica si el campo es obligatorio
     * @param {Boolean} [restricted=false] Solo permite añadir elementos que están en el autocompletado. Si no está, se vaciará de manera automática
     * @param {Boolean} [disabled=false] Permite deshabilitar los inputs
     * @param {Number} [minLength=1] Número de caracteres requeridos para iniciar el autocompletado
     * @param {Boolean} [asList=false] Añade la clase `as-list` a los elementos para mostrarlos uno debajo de otro en lugar de en línea
     *
     * @requires app.component:labelForm
     */
    angular
        .module('app')
        .component('tags', {
            controller: Controller,
            controllerAs: 'ctrl',
            templateUrl: 'app/components/form/tags/tags.html',
            bindings: {
                ngModel: '=',
                id: '@',
                label: '@',
                placeholder: '@',
                tooltip: '@',
                key: '@',
                display: '@',
                resource: '<',
                method: '@',
                queryParam: '@',
                parameters: '<',
                required: '<',
                restricted: '<',
                disabled: '<',
                minLength: '@',
                asList: '<'
            }
        });

    function Controller() {
        var vm = this;

        // Valores por defecto
        vm.key = vm.key || "nombre";
        vm.display = vm.display || "nombre";
        vm.method = vm.method || "query";
        vm.queryParam = vm.queryParam || "query";
        vm.minLength = vm.minLength || 1;

        if (!vm.ngModel) {
            vm.ngModel = [];
        }

        //

        vm.setElement = function (item) {
            if (item.id && !vm.multiple) {
                vm.ngModel.id = item.id;
            }
        };

        vm.loadTags = function (query) {
            var params = !vm.parameters ? {} : vm.parameters;
            params[vm.queryParam] = query;
            return vm.resource[vm.method](params).$promise.then(function (data) {
                if (Array.isArray(data)) {
                    return data;
                } else {
                    return data.content;
                }
            });
        };
    }

})();
